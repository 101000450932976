
#reservation {
    align-content: center;
    color: white;
    margin-top: 80px;
    min-height: 75vh;
}

#reservation .container {
    padding: 3.5rem 5rem;
    display: flex;
    justify-content: center;
}

#reservation .row {
    display: flex;
}

.reservation-box h3, .confirmation-box h3 {
    color: #C6AC8E;
    line-height: 35px;
    padding-top: 60px;

}

.reservation-box p, .confirmation-box p {
    line-height: 45px;
}

#reservation .reservation-box,
#reservation .reservation-form {
   
    float: left;
    padding: 0 40px;

}

.form-container {
    width: 525px;
    height: auto;
    background-color: #ffffff;
    border-radius: 5px;
}

.confirmation-box{
    text-align: center;
}

@media (max-width:1024px) {

    #reservation .row {
        display: block;
        text-align: center;
    }

    #reservation .reservation-box,
    #reservation .reservation-form {
        width: 100%;
        float: none;
        padding-bottom: 40px;
        padding: 0;
    }

    .reservation-box h3 {
        color: #C6AC8E;
        line-height: 35px;
        padding-top: 0px;
    }

    .form-container{
        margin-top: 30px;
    }

    #reservation .container{
        padding-bottom: 0;
    }
    .form-container{
        width: auto;
        }
}
@media (max-width:768px){
    .reservation-box p, .confirmation-box p {
        padding-top: 10px;
        line-height: 25px;
        
    }
}
@media (max-width:480px) {

    #reservation .container {
        padding: 0 30px;
    }

}
@media (max-width: 1439px) {
    #footer {
        position: relative;
    }
}