* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;

}

html {
    overflow-x: hidden;
    scroll-padding-top: 0px;
    /* save space for navbar */
    scroll-behavior: smooth;
    background-color: black;
    color: white;
    font-family: "Fira Sans", sans-serif;
    font-weight:200;
}

header {
    top: 0;
    width: 100%;
    background: black;
    padding: 1.5rem;
    position: fixed;
    z-index: 9999;
}

header .nav {
    display: flex;
    max-width: 1200px;
    float: right;
    margin-right: 10px;
    text-align: center;
}

#logoImg {
    width: 140px;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.navbar {
    display: flex;
    align-items: center;
}

header .navbar a {
    font-size: 16px;
    float: left;
    padding: 0.5rem 2rem;
    margin-right: 5px;
    color: white;
    text-align: center;
    text-decoration: none;
}

header .navbar a.active {
    
}

#bookNow {
    border-style: solid;
    border-color: #C6AC8E;
    padding: 15px 20px;
    border-radius: 5px;
    color: white;
}

#bookNow:hover {
    background-color: #C6AC8E;
    text-decoration: none;
    
}
/*background-color: #434343;*/
header .navbar a:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 8px;
}

#menu-icon {
    display: none;
    background-color: rgb(56, 56, 56);
    color: rgb(255, 255, 255);
    font-size: 25px;
    float: right;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 1rem;
}

#menu-icon:hover {
    background-color: #272727;
}

#home {
    text-align: center;
    height: 100vh;
    font-family: 'Cormorant Garamond', serif;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: contain;
    display: flex;
    cursor: grab;
}

.swiper-slide {
    text-align: center;
    font-size: 1vw;
    display: flex;
    background: #000000;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.image-container {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1vw;
}

.swiper .swiper-button-prev,
.swiper .swiper-button-next {
    color: #949494;
    cursor: pointer;
}

.swiper .swiper-button-prev:hover,
.swiper .swiper-button-next:hover {
    color: #4d4d4d;
}

.swiper-slide img {
    width: 100%;
    display: block;
    height: auto;
    position: relative;
    font-size: 1vw;
}

.swiper .swiper-pagination {
    padding: 0px 0px;
    border-radius: 4px;
    background: transparent;
}

.swiper .swiper-pagination * {
    background: #b0b0b0;
}

.swiper-slide-title {
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 2.5vw;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation-delay: 2s;
    animation: fadeIn 2.5s;
    -webkit-animation: fadeIn 2.5s;
    -moz-animation: fadeIn 2.5s;
    -o-animation: fadeIn 2.5s;
    -ms-animation: fadeIn 2.5s;
    animation-fill-mode: forwards;
    font-style: italic;
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}
.menu {
    height: 100vh;
}

.menu-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
}

#grid1 {
    overflow: hidden;
    position: relative;
}

#grid1 p {
    z-index: 1000;
    color: white;
    position: absolute;
    top: 20%;
    left: 33%;
    font-size: 1.8vw;
    font-style: italic;
    font-weight: bold;
}

#grid1 p::after {
    content: "";
    display: block;
    width: 8em;
    max-width: 73%;
    border-bottom: 0.17em solid #BF7534;
    margin: 0.4em 0;
}

#menuImg1 {
    max-width: 100%;
    max-height: auto;
    object-fit: cover;
    align-items: center;
    display: block;
    margin: 0 auto;
    opacity: 85%;
    transform-origin: 65% 75%;
    transition: transform 1s, filter .5s ease-out;
    
}

#menuImg1:hover {
    transform: scale(1.3);
    overflow: hidden;
    opacity: 100%;
}

#omakase {
    text-align: right;
    padding-top: 4%;
    padding-right: 15%;
    font-size: 18px;
}

#grid2 p {
    width: 100%;
    text-align: justify;
    line-height: 3vw;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 1rem;
}

#setdinner {
    padding-left: 25%;
    font-size: 18px;
    font-weight: bold;
}

#dinnerPrice {
    padding-top: 0.5rem;
    list-style: none;
    line-height: 2.5vw;
    padding-left: 25%;
}

#drinkMenu {
    z-index: 999;
    background-color: #C6AC8E; 
    margin-top: 1rem;
    margin-left: 12vw;
    border-radius: 5px;
    padding: 10px 40px;
    cursor: pointer;
    margin-bottom: 8%;

}

#drinkMenu a {
    text-decoration: none;
    color:#000000;
}

#drinkMenu:hover {
    background-color: #b18b5f;
}

.modal-content {
    position: absolute;
    z-index: 9999;
    padding-top: 70px;
    top: 100%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    max-width: 100%;
}

#grid4 {
    overflow: hidden;
    position: relative;
}

#grid4 p {
    z-index: 1000;
    color: white;
    position: absolute;
    right: 15%;
    top: 40%;
    font-size: 1.8vw;
    font-style: italic;
    font-weight: bold;
}

#grid4 p::after {
    content: "";
    display: block;
    width: 8em;
    max-width: 92%;
    border-bottom: 0.17em solid #7C8C03;
    margin: 0.4em 0;
}

#menuImg2 {
    max-width: 100%;
    max-height: auto;
    display: block;
    margin: 0 auto;
    transition: transform .5s, filter 1s ease-in-out;
    filter: grayscale(25%);
}

#menuImg2:hover {
    filter: grayscale(0);
    transform: scale(1.1);
}

#aboutUs {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

#aboutUsImg {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#aboutUsMobile {
    display: none;
}


#footer{
    background-color: #131313;
    margin-top: 0px;
    padding: 50px 0;
}

.footerLogoContainer{
    display: flex;
    justify-content: center;
}

#footerLogo{
    display: flex;
    justify-content: center;
    width: 130px;
    margin-bottom: 35px;
}
.contactWrap .row{
    display: flex;
    justify-content: center;
    text-decoration: none;
}
.contactWrap .row-title{
    font-weight: 600;
    text-align: center;
}
.contactWrap .col{
float: left;
width: 33.33%;
padding: 0px 60px;
text-align: center;
}
.contactWrap .col .row-title{
    padding-bottom: 10px;
}

#openingHours li, #contact li{
    list-style-type: none;
    padding-bottom: 10px;
}
.vl {
    border-left: 2px solid rgb(68, 68, 68);
    height: 80px;
    text-align: center;
  }

#icons{
    display: flex;
    justify-content: center;
}
  #icons i {
    padding: 2px 8px;
    font-size: 22px;
    color: #e5e5e5;
    cursor: pointer;
}

#icons i:hover {
    color: #a8a8a8;
}
@media (max-width:1024px) {
    header {
        padding: 0.7rem;
        padding-bottom: 1.5rem;
    }

    header .navbar a {
        font-size: 18px;
        padding: 0.5rem 1.5rem;
        color: white;
        text-align: center;
    }

    #logoImg {
        padding-top: 1rem;
    }

    header .logo {
        margin-top: -2px;
        float: left;
        font-size: 18px;
    }

    header .navbar.active {
        width: 100%;
    }

    #menu-icon {
        display: block;
    }


    header .navbar {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #000000;
        width: 0;
        overflow: hidden;
        padding: 20px 0;
    }

    header .navbar a {
        display: block;
        width: 100%;
        padding: 0.5rem 0;
        margin: 0.5rem 0;
        font-size: 15px;
        background-color: #000000;
    }

.bookingButton{
    display: block;
    margin: auto;
    width: fit-content;
    
}

#bookNow{
    width: auto;
}

    #menu {
        padding-top: 10vw;
        padding-bottom: 10vw;
    }



}

@media (max-width:768px) {
    header {
        padding: 0;
        padding-bottom: 10px;
    }

    header .logo {
        float: left;
        font-size: 18px;
        padding-top: 1.2rem;
    }

    #logoImg {
        padding-top: 1rem;
        width: 100px;
    }


#footer{
    margin-top: 30px;
    padding: 10vw ;
}
    .contactWrap .row{
        display:block;

    }

    .contactWrap .col{
        float: none;
        width: 100%;
        padding: 20px 0px;
    }
    .vl{
        display: none;
    }
}

@media (max-width:480px) {
    html {
        scroll-padding-top: 1.5rem;
    }

    header {
        padding: 0rem;
        padding-bottom: 10px;
    }

    #menu-icon {
        display: block;
    }

    .swiper-slide-title {
        bottom: 4%;
        font-size: 4.5vw;
    }

    .swiper .swiper-pagination {
        position: absolute;
        padding-bottom: 55vw;

    }

    .swiper .swiper-button-prev,
    .swiper .swiper-button-next {
        display: none;
    }

    #menuImg1,
    #menuImg2 {
        max-width: 100%;
        z-index: -999;
        opacity: 1;
    }

    #grid4 {
        overflow: visible;
    }

    #grid1 p,
    #grid4 p {
        display: none;
    }

    #menuImg2 {
        position: relative;
        margin-top: -10vw;
        filter: grayscale(0);
    }

    #omakase,
    #menu p,
  
    #dinnerPrice {
        padding: 10px;
        margin: 0;
        width: 100%;
        text-align: center;
        line-height: 8vw;
    }
    #setdinner{
        text-align: center;
        margin: 0;
        padding:0;
    }
    .menu-container {
        display: block;
        position: relative;
    }

    #omakase {
        padding-top: 1rem;
    }

    #menu p {
        padding: 0rem 2rem;
        text-align: justify;
    }

    #setdinner {
        padding-top: 8vw;
    }

    #dinnerPrice {
        margin: 0 auto;
        display: block;
        padding-bottom: 10vw;

    }

    #drinkMenu {
        margin: 0 auto;
        display: block;
        align-items: center;
        text-align: center;
        font-size: 3vw;
        position: relative;

    }

    #aboutUsImg {
        display: none;
        padding: 0;
        margin: 0;
    }

    #aboutUsMobile {
        display: flex;
        max-width: 100%;
        padding-top: 55vw;
        padding-bottom: 0vw;
    }

    #aboutUs {
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        height: 100vh;
    }

    #icons {
   

    }

    #icons i {
        padding: 0 10vw;
        font-size: 6vw;

    }


}